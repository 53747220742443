import React from 'react';
import { connect } from "react-redux";
import ReferenceItem from './ReferenceItem/ReferenceItem';

class References extends React.Component {
  render() {
    return (
		<section id="references" className="section section-references">
			<div className="animate-up">
				<h2 className="section-title">References</h2>

				<div className="section-box">
                    <ul className="ref-slider">
                        {this.props.references.map((reference) => (
                            <ReferenceItem
                                key={reference.name}
                                reference={reference}
                            />
                        ))}
                    </ul>
                    <div className="ref-slider-nav">
                        <span id="ref-slider-prev" className="slider-prev"></span> <span id="ref-slider-next"
                        className="slider-next"></span>
                    </div>
				</div>
			</div>
		</section>
	);
  }
}

const mapStateToProps = (state = {
    references: []
}) => {
    return {
        references: state.references,
    };
}

export default connect(mapStateToProps)(References);
