import React from 'react';

const SocialIcon = ({url, iconClass}) => (
	<li>
        <a className="ripple-centered" href={url} target="_blank" rel="noreferrer">
            <i className={"rsicon " + iconClass }></i>
        </a>
    </li>
);

export default SocialIcon;
