import React from 'react';
import { connect } from "react-redux";

class Interests extends React.Component {
  render() {
    return (
		<section id="interests" className="section section-interests">
			<div className="animate-up">
				<h2 className="section-title">My Interests</h2>

				<div className="section-box">
                    <p>{this.props.aboutMe}</p>

                    <ul className="interests-list">
                        {
                            this.props.interests.map((interest) => {
                                return (
                                    <li key={interest.title}><i className={`map-icon ${interest.icon}`}></i> <span>{interest.title}</span></li>
                                )
                            })
                        }
                    </ul>
				</div>
			</div>
		</section>
	);
  }
}

const mapStateToProps = (
    state = {
        about: {},
        interests: []
    }
) => {
    return {
        aboutMe: state.about.aboutMe,
        interests: state.interests,
    };
};

export default connect(mapStateToProps)(Interests);
