import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileNav.scss';

class MobileNav extends React.Component {
  render() {
    return (
		<div className="mobile-nav">
			<button className="btn-mobile mobile-nav-close">
			</button>

			<div className="mobile-nav-inner">
				<nav id="mobile-nav" className="nav">
				<ul className="clearfix">
					<li><a href="#about">About</a></li>
					<li><a href="#skills">Skills</a></li>
					<li><a href="#portfolio">Portfolio</a></li>
					<li><a href="#experience">Experience</a></li>
					<li><a href="#references">References</a></li>
					<li><a href="#blog">Blog</a></li>
					<li><a href="#interests">Interests</a></li>
					<li><a href="#contact">Contact <span></span></a></li>
				</ul>
				</nav>
			</div>
        </div>
	);
  }
}

const MobileNavPropTypes = {
	// always use prop types!
};

MobileNav.propTypes = MobileNavPropTypes;

export default MobileNav;
