import React from 'react';
import { connect } from "react-redux";

class DownloadResume extends React.Component {
    render() {
        return (
            <p>
                <a
                    className="btn btn-lg btn-border ripple"
                    target="_blank"
                    href={this.props.resumeUrl}
                    download={this.props.resumeUrl}
                    rel="noreferrer"
                >
                    Download Resume
                </a>
            </p>
        );
    }
}

const mapStateToProps = (state = {
    about: {
        resumeUrl: ""
    }
}) => {
    return {
        resumeUrl: state.about.resumeUrl,
    };
}

export default connect(mapStateToProps)(DownloadResume);
