import React from "react";

class BlogItem extends React.Component {
    render() {
        return (
            <div
                className="grid-item"
                onClick={() => window.open(this.props.blog.url, 'mywindow')}
                style={{ cursor: "pointer" }}
            >
                <article className="post-box">
                    <div className="post-media">
                        <div className="post-image">
                            <img
                                src={this.props.blog.image}
                                alt={this.props.blog.title}
                            />
                        </div>
                    </div>

                    <div className="post-data">
                        <time className="post-datetime">
                            <span className="day">{this.props.blog.day}</span>{" "}
                            <span className="month">{this.props.blog.month}</span>
                        </time>

                        <div className="post-tag">
                            <b>{this.props.blog.tags}</b>
                        </div>

                        <h3 className="post-title">
                            {this.props.blog.title}
                            <br />
                        </h3>

                        <div className="post-info">
                            <i className="rsicon rsicon-user"></i>
                            {this.props.blog.author}
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default BlogItem;
