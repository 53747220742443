import db from "./firebase";
import differenceInYears from "date-fns/differenceInYears";

export const UPDATE_STATE = "UPDATE_STATE";
export const setUpdateState = (updateState) => ({
    type: SET_ABOUT,
    updateState,
});

export const SET_ABOUT = "SET_ABOUT";
const setAbout = (
    about,
    skills,
    portfolio,
    workExperience,
    education,
    references,
    blogs,
    interests,
    contact,
) => ({
    type: SET_ABOUT,
    about,
    skills,
    portfolio,
    workExperience,
    education,
    references,
    blogs,
    interests,
    contact,
});

const sortByPosition = (array) => {
    return array.sort((a, b) => a.position > b.position ? 1 : -1);
}

export const startSetAbout = () => {
    return (dispatch, getState) => {
        return db
            .collection("me")
            .doc("about")
            .get()
            .then((doc) => {
                const data = doc.data();
                const about = {
                    name: data.name,
                    position: data.position,
                    age: differenceInYears(
                        new Date(),
                        data.dateOfBirth.toDate()
                    ),
                    address: data.address,
                    email: data.email,
                    phone: data.phone,
                    imageUrl: data.imageUrl,
                    resumeUrl: data.resumeUrl,
                    description: data.description,
                    socialLinks: data.socialLinks,
                    profileImage: data.profileImage,
                    profileImageThumbnail: data.profileImageThumbnail,
                    aboutMe: data.aboutMe,
                    location: data.location,
                };
                const skills = sortByPosition(data.skills);
                const portfolio = sortByPosition(data.portfolio);
                const workExperience = sortByPosition(data.workExperience);
                const education = sortByPosition(data.education);
                const references = sortByPosition(data.references);
                const blogs = sortByPosition(data.blogs);
                const interests = sortByPosition(data.interests);
                const contact = sortByPosition(data.contact);
                dispatch(
                    setAbout(
                        about,
                        skills,
                        portfolio,
                        workExperience,
                        education,
                        references,
                        blogs,
                        interests,
                        contact,
                    )
                );
                console.log(education);
            });
    };
};

export const SET_PORTFOLIO_EXPANDED = "SET_PORTFOLIO_EXPANDED";
export const setPortfolioExpanded = (isPortfolioExpanded) => ({
    type: SET_PORTFOLIO_EXPANDED,
    isPortfolioExpanded,
});
