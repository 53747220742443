import React from 'react';
import { connect } from "react-redux";
import SocialIcon from '../SocialIcon/SocialIcon';

class SocialIcons extends React.Component {
  render() {
    return (
        <div className="profile-social">
            <ul className="social">
                {
                    this.props.socialLinks.map(({url, iconClass}) => (
                        <SocialIcon key={url} url={url} iconClass={iconClass} />    
                    ))
                }
            </ul>
        </div>
    );
  }
}

const mapStateToProps = (
    state = {
        about: {
            socialLinks: []
        },
    }
) => {
    return {
        socialLinks: state.about.socialLinks,
    };
};

export default connect(mapStateToProps)(SocialIcons);
