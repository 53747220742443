import React from "react";
import { connect } from "react-redux";
import Content from "../Content/Content";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MobileNav from "../MobileNav/MobileNav";

class Root extends React.Component {
    render() {
        return (
            <div
                className={`home header-has-img ${
                    this.props.about.name ? "" : "loading"
                }`}
            >
                <MobileNav />

                <div className="wrapper">
                    <Header />
                    <Content />
                    <Footer />
                </div>

                <i className="rsicon rsicon-arrow-up"></i>

                <div id="overlay"></div>
                {this.props.about.name ? undefined : (
                    <div id="preloader">
                        <div className="preload-icon">
                            <span></span>
                            <span></span>
                        </div>
                        <div className="preload-text">Loading ...</div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (
    state = {
        about: {},
    }
) => {
    return {
        about: state.about,
    };
};

export default connect(mapStateToProps)(Root);
