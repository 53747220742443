import React from "react";
import { connect } from "react-redux";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-social">
                    <ul className="social">
                        {this.props.socialLinks.map(({ url, iconClass }) => (
                            <li key={url}>
                                <a
                                    className="ripple-centered"
                                    href={url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className={`rsicon ${iconClass}`}></i>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = (
    state = {
        about: {
            socialLinks: [],
        },
    }
) => {
    return {
        socialLinks: state.about.socialLinks,
    };
};

export default connect(mapStateToProps)(Footer);
