import React from "react";

class Header extends React.Component {
    render() {
        return (
            <header className="header">
                <div
                    className="head-bg"
                    style={{ backgroundImage: "url('img/uploads/cover.jpeg')" }}
                ></div>

                <div className="head-bar">
                    <div className="head-bar-inner">
                        <div className="row">
                            <div className="col-sm-3 col-xs-6">
                                <a className="logo" href="index.html">
                                    <span>Rachit</span>
                                </a>
                            </div>

                            <div className="col-sm-9 col-xs-6">
                                <div className="nav-wrap">
                                    <nav id="nav" className="nav">
                                        <ul className="clearfix">
                                            <li>
                                                <a href="#about">About</a>
                                            </li>
                                            <li>
                                                <a href="#skills">Skills</a>
                                            </li>
                                            <li>
                                                <a href="#portfolio">
                                                    Portfolio
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#experience">
                                                    Experience
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#references">
                                                    References
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#blog">Blog</a>
                                            </li>
                                            <li>
                                                <a href="#interests">
                                                    Interests
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#contact">
                                                    Contact <span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
