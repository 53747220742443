import React from "react";
import { connect } from "react-redux";
import ContactForm from "./ContactForm/ContactForm";

class Contact extends React.Component {
    
    componentDidMount() {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 2000);
    }

    render() {
        return (
            <section id="contact" className="section section-contact">
                <div className="animate-up">
                    <h2 className="section-title">Contact Me</h2>

                    <div className="row">
                        <ContactForm />

                        <div className="col-sm-6">
                            <div className="section-box contact-info has-map">
                                <ul className="contact-list">
                                    {this.props.contact.map((contact) => (
                                        <li key={contact.title} className="clearfix">
                                            <strong className="title">
                                                {contact.title}
                                            </strong>
                                            <span className="cont">
                                                {
                                                    contact.url ? (
                                                        <a href={contact.url}>
                                                            {contact.content}
                                                        </a>
                                                    ) : (
                                                        contact.content
                                                    )
                                                }
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <div
                                    id="map"
                                    data-latitude={this.props.location.latitude}
                                    data-longitude={
                                        this.props.location.longitude
                                    }
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (
    state = {
        about: {
            location: {}
        },
        contact: [],
    }
) => {
    return {
        location: state.about.location,
        contact: state.contact,
    };
};

export default connect(mapStateToProps)(Contact);
