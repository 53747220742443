import React from "react";
import { connect } from "react-redux";
import Interweave from 'interweave';

class Description extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.description.map((line) => (
                        <p key={line}><Interweave content={line} /></p>
                    ))
                }
            </div>
        );
    };
}

const mapStateToProps = (
    state = {
        about: {
            description: []
        },
    }
) => {
    return {
        description: state.about.description,
    };
};

export default connect(mapStateToProps)(Description);
