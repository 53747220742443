import React from "react";
import { connect } from "react-redux";
import { setUpdateState } from "../../../../firebase/actions";
import WorkExperienceItem from "./WorkExperienceItem/WorkExperienceItem";

class WorkExperience extends React.Component {

    render() {
        return (
            <section id="experience" className="section section-experience">
                <div className="animate-up">
                    <h2 className="section-title">Work Experience</h2>

                    <div className="timeline">
                        <div className="timeline-bar"></div>
                        <div className="timeline-inner clearfix">
                            {this.props.workExperience.map((workExperience) => (
                                <WorkExperienceItem
                                    key={workExperience.position}
                                    workExperience={workExperience}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (
    state = {
        workExperience: [],
    }
) => {
    return {
        workExperience: state.workExperience,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setUpdateState: (isUpdated) => dispatch(setUpdateState(isUpdated)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperience);
