import React from "react";
import Interweave from 'interweave';

class ReferenceItem extends React.Component {
    render() {
        return (
            <li>
                <div className="ref-box">
                    <div className="person-speech">
                        <p>
                            <Interweave content={this.props.reference.comment} />
                        </p>
                    </div>
                    <div className="person-info clearfix">
                        <img
                        className="person-img"
                            src={this.props.reference.image}
                            alt="Headshot"
                        />

                        <div className="person-name-title">
                            <span className="person-name">{this.props.reference.name}</span>
                            <span className="person-title">
                                {this.props.reference.title}
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default ReferenceItem;
