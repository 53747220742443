import React from "react";
import { connect } from "react-redux";
import ProfessionalSkill from "./ProfessionalSkill/ProfessionalSkill";

class Skills extends React.Component {

    render() {
        let prevSkill;
        return (
            <section id="skills" className="section section-skills">
                <div className="animate-up">
                    <h2 className="section-title">Professional Skills</h2>
                    <div className="section-box">
                        {                            
                            this.props.skills.map((skill, index) => {
                                if (index % 2 === 0) {
                                    prevSkill = skill;
                                    return undefined;
                                } else {
                                    return (
                                        <div className="row" key={skill.name}>
                                            <ProfessionalSkill skill={prevSkill}/>
                                            <ProfessionalSkill skill={skill}/>
                                        </div>
                                    );
                                }                                
                            })
                        }
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state = {
    skills: []
}) => {
    return {
        skills: state.skills,
    };
}

export default connect(mapStateToProps)(Skills);
