import React from "react";
import validator from 'validator'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import db from '../../../../../firebase/firebase';

const defaultState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    nameHasError: false,
    emailHasError: false,
    messageHasError: false,
    nameIsValid: false,
    emailIsValid: false,
    messageIsValid: false,
    uploadSuccess: false,
    uploadFailure: false,
}

class ContactForm extends React.Component {
    state = defaultState;

    onNameChange = (e) => {
        const name = e.target.value;
        let nameIsValid = name ? true : false;
        this.setState(() => ({ name, nameIsValid, nameHasError: !nameIsValid }));
    };

    onEmailChange = (e) => {
        const email = e.target.value;
        let emailIsValid = email ? true : false;
        this.setState(() => ({ email, emailIsValid, emailHasError: !emailIsValid }));
    };

    onSubjectChange = (e) => {
        const subject = e.target.value;
        this.setState(() => ({ subject }));
    };

    onMessageChange = (e) => {
        const message = e.target.value;
        let messageIsValid = message ? true : false;
        this.setState(() => ({ message, messageIsValid, messageHasError: !messageIsValid }));
    };

    uploadMessage = (e) => {
        e.preventDefault();

        let hasError = false
        if (!this.state.name) {
            this.setState(() => ({ nameIsValid: false, nameHasError: true }));
            hasError = true;
        }
        if (!this.state.email || !validator.isEmail(this.state.email)) {
            this.setState(() => ({ emailIsValid: false, emailHasError: true }));
            hasError = true;
        }

        if (!this.state.message) {
            this.setState(() => ({ messageIsValid: false, messageHasError: true }));
            hasError = true;
        }

        if (!hasError) {
            let formData = {
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
                time: new Date(),
            };

            db.collection("contact")
                .add(formData)
                .then(() => {
                    toast.success('I got the message, thanks!');
                    this.setState(defaultState);
                }).catch((e) => {
                    toast.error('Message failed, please send again!');
                })
        }
    }

    render() {
        return (
            <div className="col-sm-6">
                <div className="section-box contact-form">
                    <h3>Feel free to contact me</h3>

                    <form className="rsForm" onSubmit={this.uploadMessage}>
                        <div className={`input-field ${this.state.nameIsValid ? "used" : ""} ${this.state.nameHasError ? "error" : ""}`}>
                            <label>Name</label>
                            <input
                                type="text"
                                name="rsName"
                                value={this.state.name}
                                onChange={this.onNameChange}
                            />
                            <span className="line"></span>
                        </div>
                        
                        <div className={`input-field ${this.state.emailIsValid ? "used" : ""} ${this.state.emailHasError ? "error" : ""}`}>
                            <label>Email</label>
                            <input
                                type="email"
                                name="rsEmail"
                                value={this.state.email}
                                onChange={this.onEmailChange}
                            />
                            <span className="line"></span>
                        </div>

                        <div className="input-field">
                            <label>Subject</label>
                            <input
                                type="text"
                                name="rsSubject"
                                value={this.state.subject}
                                onChange={this.onSubjectChange}
                            />
                            <span className="line"></span>
                        </div>

                        <div className={`input-field ${this.state.messageIsValid ? "used" : ""} ${this.state.messageHasError ? "error" : ""}`}>
                            <label>Message</label>
                            <textarea
                                rows="5"
                                name="rsMessage"
                                value={this.state.message}
                                onChange={this.onMessageChange}
                            ></textarea>
                            <span className="line"></span>
                        </div>

                        <span className="btn-outer btn-primary-outer ripple">
                            <input
                                className="rsFormSubmit btn btn-lg btn-primary"
                                type="submit"
                                value="Send"
                            />
                        </span>

                        <ToastContainer
                            position="top-left"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            draggable
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default ContactForm;
