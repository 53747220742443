import "./App.css";
import React from "react";
import { Provider } from "react-redux";
import "./firebase/firebase";
import "./firebase/configureStore";

import configureStore from "./firebase/configureStore";
import { startSetAbout } from "./firebase/actions";
import Root from "./components/Root/Root";

const store = configureStore();

class App extends React.Component {
    constructor(props) {
        super(props);
        store.dispatch(startSetAbout());
    }

    render() {
        return (
            <Provider store={store}>
                <div className="App">
                    <Root />
                </div>
            </Provider>
        );
    }
}

export default App;
