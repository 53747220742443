import React from 'react';

class PortfolioItem extends React.Component {
    render() {
        return (
            <div className={"grid-item size11 " + this.props.category}>
                <div className="grid-box">
                    <figure className="portfolio-figure">
                        <img src={this.props.image} alt="" />
                        <figcaption className="portfolio-caption">
                            <div className="portfolio-caption-inner">
                                <h3 className="portfolio-title">{this.props.title}</h3>
                                <h4 className="portfolio-cat">{this.props.category}</h4>

                                <div className="btn-group">
                                    <a className="btn-link"
                                        href={this.props.url}
                                        target="_blank" rel="noreferrer">
                                        <i className="rsicon rsicon-link"></i>
                                    </a> 
                                    <a className="portfolioFancybox btn-zoom"
                                        data-fancybox-group={`portfolioFancybox${this.props.position}`}
                                        href={`#portfolio${this.props.position}-inline1`}><i className="rsicon rsicon-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </figcaption>
                    </figure>

                    <div id={`portfolio${this.props.position}-inline1`} className="fancybox-inline-box">
                        <div className="inline-embed" data-embed-type="image"
                        data-embed-url={this.props.image}>
                        </div>
                        <div className="inline-cont">
                            <h2 className="inline-title">{this.props.title}</h2>

                            <div className="inline-text">
                                <p>{this.props.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PortfolioItem;
