import React from "react";
import { connect } from "react-redux";
import Description from "./Description/Description";
import DownloadResume from "./DownloadResume";
import SocialIcons from "./SocialIcons/SocialIcons";

class About extends React.Component {
    constructor(){
        super();
        this.state = {loaded: false};
    }

    render() {
        return (
            <section id="about" className="section section-about">
                <div className="animate-up">
                    <div className="section-box">
                        <div className="profile">
                            <div className="row">
                                <div className="col-xs-5">
                                    <div className="profile-photo">
                                        {this.state.loaded ? null :
                                            <img src="img/uploads/profile.jpeg" alt="Rachit Goyal" />
                                        }
                                        <img src={this.props.about.profileImage} alt="Rachit Goyal" 
                                            style={this.state.loaded ? {} : {display: 'none'}}
                                            onLoad={() => this.setState({loaded: true})} />
                                    </div>
                                </div>
                                <div className="col-xs-7">
                                    <div className="profile-info">
                                        <div className="profile-preword">
                                            <span>Hello</span>
                                        </div>
                                        <h1 className="profile-title">
                                            <span>I'm</span>{" "}
                                            {this.props.about.name}
                                        </h1>

                                        <h2 className="profile-position">
                                            {this.props.about.position}
                                        </h2>
                                    </div>
                                    <ul className="profile-list">
                                        {this.props.contact.map((contact) => (
                                            <li key={contact.title} className="clearfix">
                                                <strong className="title">
                                                    {contact.title}
                                                </strong>
                                                <span className="cont">
                                                    {
                                                        contact.url ? (
                                                            <a href={contact.url}>
                                                                {contact.content}
                                                            </a>
                                                        ) : (
                                                            contact.content
                                                        )
                                                    }
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <SocialIcons />
                    </div>

                    <div className="section-txt-btn">
                        <DownloadResume />
                        <Description />
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state = {
    about: {},
    contact: [],
}) => {
    return {
        about: state.about,
        contact: state.contact,
    };
}

export default connect(mapStateToProps)(About);
