import React from "react";

class WorkExperienceItem extends React.Component {
    render() {
        const isEven = this.props.workExperience.position % 2 === 0
        return (
            <div className={`timeline-box timeline-box-${isEven ? "left" : "right"}`}>
                <span className="dot"></span>

                <div className={`timeline-box-inner animate-${isEven ? "right" : "left"}`}>
                    <span className="arrow"></span>

                    <div className="date">{this.props.workExperience.duration}</div>
                    <h3>{this.props.workExperience.companyName}</h3>
                    <h4>{this.props.workExperience.designation}</h4>
                    <div dangerouslySetInnerHTML={{__html: this.props.workExperience.description}} />
                </div>
            </div>
        );
    }
}

export default WorkExperienceItem;
