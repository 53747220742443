import React from "react";
import { connect } from "react-redux";
import EducationItem from "./EducationItem/EducationItem";

class Education extends React.Component {
    render() {
        return (
            <section id="education" className="section section-education">
                <div className="animate-up">
                    <h2 className="section-title">Education</h2>

                    <div className="timeline">
                        <div className="timeline-bar"></div>
                        <div className="timeline-inner clearfix">
                            {this.props.education.map((education) => (
                                <EducationItem
                                    key={education.position}
                                    education={education}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (
    state = {
        education: [],
    }
) => {
    return {
        education: state.education,
    };
};

export default connect(mapStateToProps)(Education);
