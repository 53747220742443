import { createStore, applyMiddleware, compose } from "redux";
import { UPDATE_STATE, SET_ABOUT, SET_PORTFOLIO_EXPANDED } from "./actions";
import thunk from "redux-thunk";

const componseEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const store = createStore((state = {}, action) => {
        switch (action.type) {
            case UPDATE_STATE: {
                return {
                    ...state,
                    updateState: action.updateState,
                };
            }
            case SET_ABOUT: {
                return {
                    ...state,
                    about: action.about,
                    skills: action.skills,
                    portfolio: action.portfolio,
                    workExperience: action.workExperience,
                    education: action.education,
                    references: action.references,
                    blogs: action.blogs,
                    interests: action.interests,
                    contact: action.contact,
                };
            }
            case SET_PORTFOLIO_EXPANDED: {
                return {
                    ...state,
                    isPortfolioExpanded: action.isPortfolioExpanded,
                };
            }
            default: {
            }
        }
    }, componseEnhancers(applyMiddleware(thunk)));
    return store;
};

export default configureStore;
