import React from "react";

class ProfessionalSkill extends React.Component {
    render() {
        return (
            <div className="col-sm-6">
                <div className="progress-bar">
                    <div className="bar-data">
                        <span className="bar-title">{this.props.skill.name}</span>{" "}
                        <span className="bar-value">{this.props.skill.percent + "%"}</span>
                    </div>
                    <div className="bar-line">
                        <span className="bar-fill" data-width={this.props.skill.percent + "%"}
                            style={{width: this.props.skill.percent + "%"}}></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfessionalSkill;
