import React from 'react';
import { connect } from "react-redux";
import { setPortfolioExpanded } from '../../../../firebase/actions';
import PortfolioItem from './PortfolioItem/PortfolioItem';

class Portfolio extends React.Component {

    updateIsPortfolioExpanded = () => {
        this.props.setIsPortfolioExpanded(!this.props.isPortfolioExpanded);
    }

    render() {
        return (
            <section id="portfolio" className="section section-portfolio">
                <div className="animate-up">
                    <h2 className="section-title">Portfolio</h2>

                    <div className="filter">
                        <div className="filter-inner">
                            <div className="filter-btn-group">
                            <button data-filter="*">All</button>
                            <button data-filter=".android">Android</button>
                            <button data-filter=".ios">iOS</button>
                            </div>
                            <div className="filter-bar">
                            <span className="filter-bar-line"></span>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="grid-sizer"></div>
                        {
                            this.props.portfolio.map((app, index) => {
                                if (index < 6 || this.props.isPortfolioExpanded) {
                                    return (
                                        <PortfolioItem key={app.position} title={app.title} 
                                            category={app.category} description={app.description}
                                            image={app.image} url={app.url} position={app.position} />
                                    );
                                } else {
                                    return undefined;
                                }
                            })
                        }
                    </div>
                    <div className="grid-more" onClick={this.updateIsPortfolioExpanded}>
                        <button className="btn btn-border ripple">
                            <i className={`rsicon ${this.props.isPortfolioExpanded ? "rsicon-arrow-up" : "rsicon-arrow-down"}`}></i>
                        </button>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state = {
    portfolio: [],
    isPortfolioExpanded: false
}) => {
    return {
        portfolio: state.portfolio,
        isPortfolioExpanded: state.isPortfolioExpanded
    };
}

const mapDispatchToProps = (dispatch) => ({
    setIsPortfolioExpanded: (isExpanded) => dispatch(setPortfolioExpanded(isExpanded))
});

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
