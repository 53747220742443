import React from "react";
import { connect } from "react-redux";
import BlogItem from "./BlogItem/BlogItem";

class Blog extends React.Component {
    render() {
        return (
            <section
                id="blog"
                className="section section-blog"
            >
                <div className="animate-up">
                    <h2 className="section-title">From The Blog</h2>

                    <div style={{ minHeight: "480px" }}>
                        <div className="blog-grid">
                            <div className="grid-sizer"></div>
                            {this.props.blogs.map((blog) => (
                                <BlogItem key={blog.position} blog={blog} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (
    state = {
        blogs: [],
    }
) => {
    return {
        blogs: state.blogs,
    };
};

export default connect(mapStateToProps)(Blog);
