import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCU4DQzBOm4qpQuz_Jx98qKyw3okvLA12M",
    authDomain: "rachit-goyal.firebaseapp.com",
    databaseURL: "https://rachit-goyal.firebaseio.com",
    projectId: "rachit-goyal",
    storageBucket: "rachit-goyal.appspot.com",
    messagingSenderId: "269605872403",
    appId: "1:269605872403:web:ffd95f0c765c456e6d70e6",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default db;
