import React from "react";
import PropTypes from "prop-types";
import styles from "./Content.scss";
import About from "./components/About/About";
import Skills from "./components/Skills/Skills";
import Portfolio from "./components/Portfolio/Portfolio";
import WorkExperience from "./components/WorkExperience/WorkExperience";
import Education from "./components/Education/Education";
import References from "./components/References/References";
import Blog from "./components/Blog/Blog";
import Interests from "./components/Interests/Interests";
import Contact from "./components/Contact/Contact";

class Content extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="container">
          <About />

          <Skills />

          <Portfolio />

          <WorkExperience />

          <Education />

          <References />

          <Blog />

          <Interests />

          <Contact />
        </div>
      </div>
    );
  }
}

const ContentPropTypes = {
  // always use prop types!
};

Content.propTypes = ContentPropTypes;

export default Content;
