import React from "react";

class EducationItem extends React.Component {
    render() {
        let isEven = this.props.education.position % 2 === 0
        return (
            <div className={`timeline-box timeline-box-compact timeline-box-${isEven ? "left" : "right"}`}>
                <span className="dot"></span>

                <div className={`timeline-box-inner animate-${isEven ? "right" : "left"}`}>
                    <span className="arrow"></span>

                    <div className="date">
                        <span>{this.props.education.duration}</span>
                    </div>
                    <h3>{this.props.education.degree}</h3>
                    <h4>{this.props.education.institute}</h4>
                </div>
            </div>
        );
    }
}

export default EducationItem;
